exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kariera-tsx": () => import("./../../../src/pages/kariera.tsx" /* webpackChunkName: "component---src-pages-kariera-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-mapa-stranok-tsx": () => import("./../../../src/pages/mapa-stranok.tsx" /* webpackChunkName: "component---src-pages-mapa-stranok-tsx" */),
  "component---src-pages-podpora-tsx": () => import("./../../../src/pages/podpora.tsx" /* webpackChunkName: "component---src-pages-podpora-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/ArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */)
}

